import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'a-scene', 
  'a-marker', 
  'a-entity', 
  'a-text',
  'a-plane',
]

Vue.prototype.$axios = axios
if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = 'https://dapi.sas.10ji.cn/api'
} else {
  axios.defaults.baseURL = '/api'
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
