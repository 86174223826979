<template>
  <a-scene embedded arjs artoolkit="sourceType: webcam;" renderer="logarithmicDepthBuffer: true;" v-if="measuredValue">
    <a-marker markerhandler type="pattern" url="/markers/1.patt">
      <a-entity
        scale="0.03 0.03 0.03"
        gltf-model="/models/dragon/scene.gltf"
      />
    </a-marker>
    <a-marker markerhandler type="pattern" url="/markers/2.patt">
      <a-entity position="0 1.5 0">
        <a-plane color="#FFFFFF" opacity="0.23" width="1.5" height="0.2">
          <a-text blending="additive" position="-0.7 0 0.01" scale="0.5 0.5 0.5" anchor="left" font="./fonts/custom-msdf.json" negate="false" :value="`气象站：${measuredValue.device_no}`" />
        </a-plane>
        <a-plane position="-0.4 -0.22 0" color="#FFFFFF" opacity="0.23" width="0.7" height="0.2">
          <a-plane blending="additive" position="-0.225 0 0.01" color="#42EED1" opacity="0.7" width="0.25" height="0.2">
            <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="电量" />
          </a-plane>
          <a-text blending="additive" position="-0.02 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" value="100%" />
        </a-plane>
        <a-plane position="0.4 -0.22 0" color="#FFFFFF" opacity="0.23" width="0.7" height="0.2">
          <a-plane blending="additive" position="-0.225 0 0.01" color="#42EED1" opacity="0.7" width="0.25" height="0.2">
            <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="信号" />
          </a-plane>
          <a-plane blending="additive" position="0.05 -0.04 0" color="#FFFFFF" width="0.02" height="0.075" />
          <a-plane blending="additive" position="0.09 -0.025 0" color="#FFFFFF" width="0.02" height="0.1" />
          <a-plane blending="additive" position="0.13 -0.01 0" color="#FFFFFF" width="0.02" height="0.125" />
          <a-plane blending="additive" position="0.17 0 0" color="#FFFFFF" width="0.02" height="0.15" />
        </a-plane>
        <a-plane position="0 -1.04 0" anchor="left" color="#122A3A" opacity="0.39" width="1.5" height="1.4">
          <a-plane blending="additive" position="0 0.55 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="光照强度" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatValue(1003)" />
          </a-plane>
          <a-plane position="0 0.34 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="大气压强" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatValue(1005)" />
          </a-plane>
          <a-plane position="0 0.13 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="风力" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatValue(1007)" />
          </a-plane>
          <a-plane position="0 -0.08 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="风向" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatWindDirectionValue()" />
          </a-plane>
          <a-plane position="0 -0.29 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="大气湿度" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatValue(1002)" />
          </a-plane>
          <a-plane position="0 -0.5 0.01" color="#FFFFFF" opacity="0.2" width="1.4" height="0.2">
            <a-plane blending="additive" position="-0.45 0 0.01" color="#47AFFF" opacity="0.7" width="0.5" height="0.2">
              <a-text blending="additive" position="0 0 0.01" scale="0.5 0.5 0.5" align="center" font="./fonts/custom-msdf.json" negate="false" value="降雨量" />
            </a-plane>
            <a-text blending="additive" position="-0.15 0 0.01" scale="0.5 0.5 0.5" font="./fonts/custom-msdf.json" negate="false" :value="formatValue(1009)" />
          </a-plane>
        </a-plane>
      </a-entity>
    </a-marker>
    <a-entity camera></a-entity>
  </a-scene>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      measuredValue: null
    }
  },
  mounted() {
    this.getMeasuredValues();
  },
  methods: {
    async getMeasuredValues() {
      const response = await axios({
        headers: {"Authorization": "Basic MTYxMjUxMDI2MDpFS3lDOTVkRlByUTJtODBBR3Bqd2xvczNiT3ZpU2dSZg=="}, 
        url: "/orchard/measured_values",
        params: {"orchard_id": 1}
      });
      this.measuredValue = response.data.data[0];
    },
    formatValue(type) {
      const measureMap = {
        1001: { name: "大气温度", unit: "℃", float: 1 },
        1002: { name: "大气湿度", unit: "%", float: 1 },
        1003: { name: "光照强度", unit: "Lux", float: 0 },
        1004: { name: "CO2", unit: "ppm", float: 0 },
        1005: { name: "大气压强", unit: "Pa", float: 0 },
        1006: { name: "风向", unit: "°", float: 0 },
        1007: { name: "风力", unit: "m/s", float: 0 },
        1008: { name: "光合有效辐射", unit: "umol/㎡s", float: 0 },
        1009: { name: "降雨量", unit: "mm/h", float: 0 },
        1010: { name: "PM2.5", unit: "", float: 2 },
        1011: { name: "土壤pH", unit: "PH", float: 0 },
        1012: { name: "土壤温度", unit: "℃", float: 1 },
        1013: { name: "土壤湿度", unit: "%", float: 1 },
        1014: { name: "土壤EC", unit: "dS/m", float: 0 },
        1015: { name: "水质电导率", unit: "us/cm", float: 0 },
        1016: { name: "溶解氧", unit: "mg/L", float: 0 }
      };
      const measure = measureMap[type];
      const value = `${this.measuredValue.measurement[type].toFixed(measure.float)}${measure.unit}`;
      return value;
    },
    formatWindDirectionValue() {
      const value = this.measuredValue.measurement[1007] || 0;
      if (value === 0) {
        return "无风";
      }
      const measure = this.measuredValue[1006] || 0;
      if (measure >= 337.5 || measure < 22.5) {
        return "北风";
      } else if (measure >= 22.5 && measure < 67.5) {
        return "东北风";
      } else if (measure >= 67.5 && measure < 112.5) {
        return "东风";
      } else if (measure >= 112.5 && measure < 157.5) {
        return "东南风";
      } else if (measure >= 157.5 && measure < 202.5) {
        return "南风";
      } else if (measure >= 202.5 && measure < 247.5) {
        return "西南风";
      } else if (measure >= 247.5 && measure < 292.5) {
        return "西风";
      } else if (measure >= 292.5 && measure < 337.5) {
        return "西北风";
      }
    },
  }
}
</script>
